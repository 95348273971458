import React, { useContext } from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { useWindowWidth } from "@react-hook/window-size"
import Div100vh from "react-div-100vh"

import Form from "./Form"

import { Locale } from "../../context"
import Logo from "../../images/gadait-logo.inline.svg"

const Hero = ({ data }) => {
  let { locale } = useContext(Locale)
  const width = useWindowWidth()

  const hasForm = data.landingPage.formCtaTitle && data.landingPage.formSubtitleNode && data.landingPage.formCtaTitle

  return (
    <>
      <BackgroundImage
        Tag="section"
        className="hidden hero lg:block"
        fluid={data.landingPage.heroImage.fluid}
        backgroundColor="#072837"
        id={width >= 1024 && `form`}
      >
        <div className="h-full overlay">
          <div className="container">
            <div className="flex items-stretch justify-center h-full py-12 -mx-8 cover">
              <div className={`${hasForm && `w-1/2 xl:w-2/3`} flex flex-col justify-between min-h-[700px] px-8`}>
                <Link to={`/${locale}`} aria-label="Gadait International">
                  <Logo className="w-auto h-12 text-white" />
                </Link>
                <div
                  className="mt-12 hero-title"
                  dangerouslySetInnerHTML={{
                    __html:
                      data.landingPage.heroTitleNode.childMarkdownRemark
                        .html,
                  }}
                />
              </div>
              {hasForm &&
                <div className="w-1/2 px-8 xl:w-1/3">
                  <Form
                    title={data.landingPage.formTitle}
                    subtitle={
                      data.landingPage.formSubtitleNode.childMarkdownRemark
                        .html
                    }
                    ctaTitle={data.landingPage.formCtaTitle}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="block lg:hidden">
        <Div100vh className="max-h-[512px]">
          <BackgroundImage
            Tag="section"
            className="h-full hero"
            fluid={data.landingPage.heroImage.fluid}
            backgroundColor="#072837"
          >
            <div className="h-full py-6 overlay">
              <div className="container flex flex-col h-full justify-stretch">
                <Logo className="w-auto h-12 text-white" aria-label="Gadait International" />
                <div className="mt-auto">
                  <div
                    className="hero-title"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.landingPage.heroTitleNode
                          .childMarkdownRemark.html,
                    }}
                  />
                </div>
              </div>
            </div>
          </BackgroundImage>
        </Div100vh>
        {hasForm &&
          <div className="texture" id={`${width < 1024 && `form`}`}>
            <div className="container py-12">
              <Form
                title={data.landingPage.formTitle}
                subtitle={
                  data.landingPage.formSubtitleNode.childMarkdownRemark
                    .html
                }
                ctaTitle={data.landingPage.formCtaTitle}
              />
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default Hero
